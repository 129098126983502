<template>
    <div v-if="getReportProblem.modalOpen" class="ai-pop-up-wrap">
        <div class="report-bug-modal">
            <div class="report-bug-modal__top-row">
                <div class="report-bug-modal__logo">
                    <i class="fa-solid fa-bug" aria-hidden="true"></i>
                    <h3 style="color: white;">Bug Reporter</h3>
                </div>
                <button class="btn-close" @click.prevent="onCancel();"><i class="fas fa-times"></i>Close</button>
            </div>
            <div class="report-bug-modal__middle-row">
                <div>
                    <label for="bug-title">Title</label>
                    <input required type="text" name="bug-title" id="bug-title" v-model="title">
                </div>
            </div>
            <label for="more-info">Description</label>
            <textarea required name="more-info" id="more-info" cols="30" rows="3" v-model="moreInfo"></textarea>
            <div class="btn-wrap submit-wrap">
              <button class="btn-link btn-hover" @click.prevent="onSubmit()">Submit Report</button>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
    data(){
    return {
    //   selectedType: '',
    //   selectedAction: '',
      moreInfo: '',
      title: '',
    }
  },
  watch:{
    'getReportProblem.modalOpen': function() {
        document.querySelector('body').classList.add('lock-body');
    }
  },
  computed: {
    ...mapGetters("auth", ["getAuthUsername"]),
    ...mapGetters('client', ['getReportProblem', 'getClientSlug']),
  },
  methods: {
    async onSubmit(){
        try{
            const clientSystem = navigator.userAgent;
            this.$store.commit('client/setReportProblem', {prop: 'user', val: this.getAuthUsername});
            this.$store.commit('client/setReportProblem', {prop: 'clientSystem', val: clientSystem});
            this.$store.commit('client/setReportProblem', {prop: 'clientSlug', val: this.getClientSlug});
            // this.$store.commit('client/setReportProblem', {prop: 'type', val: this.selectedType});
            this.$store.commit('client/setReportProblem', {prop: 'title', val: this.title});
            // this.$store.commit('client/setReportProblem', {prop: 'action', val: this.selectedAction});
            this.$store.commit('client/setReportProblem', {prop: 'moreInfo', val: this.moreInfo});
            this.$store.commit('client/setReportProblem', {prop: 'currentURL', val: window.location.href});
            console.log('report a problem submited data', this.getReportProblem);
            await this.$store.dispatch('client/reportProblem');
            document.querySelector('body').classList.remove('lock-body');
            document.querySelector('.main-content').classList.remove('lock-section');
			document.querySelector('.section-report-bug').classList.remove('lock-section');
            document.querySelector('.sidebar').classList.remove('lock-section');
            this.$store.commit('client/resetReportProblem');
            // this.selectedType = '';
            // this.selectedAction = '';
            this.moreInfo = '';
            this.title = '';
        }catch(error){
            console.log('Error reporting a problem', error);
        }
    },
    async onCancel(){
        this.$store.commit('client/setIsOpenReportProblem', {prop: 'modalOpen', val: false});
        document.querySelector('body').classList.remove('lock-body');
        document.querySelector('.main-content').classList.remove('lock-section');
		document.querySelector('.section-report-bug').classList.remove('lock-section');
        document.querySelector('.sidebar').classList.remove('lock-section');
        this.$store.commit('client/resetReportProblem');
        // this.selectedType = '';
        // this.selectedAction = '';
        this.moreInfo = '';
        this.title = '';
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
@import './../../assets/scss/components/_social.scss';
.multiselect__tags{
    background: #3D4163;
    border: 1px solid #3D4163;
    margin: 20px 0;
}
.multiselect__input{
    background: #3D4163;
    border: 1px solid #3D4163 !important;
    color: white;
}
.multiselect__placeholder{
    color: white;
    font-size: 13px;
}
.multiselect__content-wrapper{
    background: #3D4163;
    border: 1px solid #3D4163;
}
.multiselect__element{
    margin-block: 10px !important;
}
.multiselect__element span{
    font-size: 13px;
    color: white;
}
.multiselect__tag-icon,
.multiselect__tag-icon:hover{
    color: white;
    background: rgb(58, 179, 220);
}
.multiselect__tag, 
.multiselect__option--highlight, 
.multiselect__option--highlight::after, 
.multiselect__option--selected,
.multiselect__option--selected::after{
    background: rgb(58, 179, 220);
    color: white;
};
</style>